import React from "react";
import Layout from "../components/layout";
import document from "../components/contentful";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../components/seo";

const Title = ({ children }) => (
  <h2 className="uppercase mt-16 mb-0 tracking-wide">
    {children} {/*<img src="/arrow.svg" className="inline" /> */}
  </h2>
);

export default ({ data, ...rest }) => {
  const { about, education, jobs, services } = data.contentfulAboutPage;

  return (
    <Layout>
      <SEO meta={data.contentfulAboutPage.seo} pathname="about" />
      <div className="lg:flex mb-24">
        <div className="lg:w-1/2 lg:pr-32">
          <Img
            fixed={data.portrait.childImageSharp.fixed}
            backgroundColor={`#000`}
            className="mt-4"
          />
        </div>

        <div className="lg:w-1/2">
          <div className="content">
            {document({ doc: JSON.parse(about.raw), color: "#F49579" })}
    
            {/* <p>
              I’m Claire Pedersen a brand designer based in NYC. I specialize in
              building brands from start to finish from the brand idenity phase
              all the way to executing across all mediums.
            </p>

            <p>
              I’m currently working at{" "}
              <a className="link communal" target="_blank" href="https://communalcreative.com/">
                Communal Creative
              </a>{" "}
              and was previously at Squarespace,{" "}
              <a className="link lmnop" target="_blank" href="https://lmnopcreative.com/">
                LMNOP
              </a>
              , Human & Dropbox.
            </p>

            <p>
              For inquiries about freelance work or collaborative projects, feel
              free to <a className="link email" href="mailto:claire.e.pedersen@gmail.com">email me</a>!
            </p> */}
          </div>

          <div className="md:flex">
            <div className="w-1/2">
              <Title>Services</Title>
              <ul className="mt-3">
                {services.map((s, i) => (
                  <li key={`service-${i}`}>{s}</li>
                ))}
              </ul>
            </div>

            <div className="w-1/2">
              <Title>Contact</Title>
              <ul className="mt-3">
                {Object.keys(data.site.siteMetadata.social).map((key, i) => (
                  <li key={`link-${i}`} className="capitalize">
                    <a
                      target="_blank"
                      href={data.site.siteMetadata.social[key]}
                    >
                      {key}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* <div>
              <Title>Experience</Title>
              <ul className="">
                {jobs.map((j, i) => (
                  <li className="mb-8" key={`job-${i}`}>
                    <p className="m-0">{j.organization}</p>
                    <p className="m-0">
                      {j.title} | {j.startDate}
                    </p>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>

          {/* <div>
            <Title>Education</Title>
            <p className="">{education}</p>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        social {
          twitter
          email
          instagram
          linkedin
        }
      }
    }
    contentfulAboutPage(id: { eq: "01416110-ce47-5980-8126-a9b01279313f" }) {
      seo {
        title
        description
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      id
      education
      jobs {
        title
        startDate
        organization
        endDate
      }
      services
      about {
        raw
      }
    }
    portrait: file(relativePath: { eq: "claire.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
